*{
    color:#161616;
}

a{
     color:#161616 !important;
}

.par{
    background-color: cadetblue;
}

body{
    background-color: #fff;
    color:#161616;
}

.banner{
    margin-top:70px;
}

.head_left{
    padding-left: 50px;;
}

.text-bold{
    font-weight: bold;;
}

.text_resena{
    margin-top:20px;
    text-align: justify;
    padding-right: 50px;;
}

.text_main{
   
         font-size:56px;
         font-weight: 700;
         margin:0;
         padding:0;
         text-transform: uppercase;
}

.details{
    margin-top:15px;
}
.deatils h1{
    font-size:12px !important;
}

.details .info_one{
        
    padding:10px;
    padding-left: 15%;
    padding-right: 15%;
}


.text-white{
     color:#fff !important;
}

.text-right{
    text-align:right;
    padding:5px;
}

.line_main{
    border-bottom: 4px solid #0faa5d;
    width:220px;
}

.btn_green{
    background: #89d5ad; 
    cursor: pointer;
}
.btn_green:hover{
    background: #0dab5c;
}

.btn_orange{
    background: #ffb540; 
    cursor: pointer;
}

.btn_orange:hover{
    background: #f4bd66;
}

@media only screen and (max-width : 900px) {

                    .form_ini{
                        margin-top: 50px;
                    }

    
    }
