.zona_login input{
    background-color: #ade2c8;
    margin-bottom: 10px;
    border:1px solid #c9c9c9;
}

.zona_login input:focus{
    background-color: #ade2c8;
}

.zona_login{
    padding:50px !important;
    border-radius:24px;
 
   
}

.form button{
    margin-top: 10px;
    color:#20140c;
    font-weight:600;
}

.footer{
    text-align: center;
    margin-top: 5px;
}

.footer a{
    color:#20140c;
      font-weight: 600;
}

.zona_login .titulo{
    text-align: center;
     font-size: 24px;
     color:#20140c;
     margin-bottom: 10px;
     font-weight: 600;;
}